import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Cypress({ styles, ...props }) {
  const Elements = useComponents()
  const main_color = styles.forElement("content").color

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div
        className="formkit-background"
        style={{
          backgroundColor: styles.forElement("background").backgroundColor
        }}
      />
      <div
        className="formkit-background"
        style={styles.forElement("background")}
      />

      <div
        className="formkit-card"
        style={styles.forElement("card_background")}
      >
        <div className="formkit-main">
          <header>
            <Elements.Heading
              className="formkit-heading-primary font-display"
              name="header"
              defaults={{
                content: "Jane Doe"
              }}
            />
          </header>
          <div role="main">
            <Elements.Region className="formkit-content" name="content">
              <Elements.Content
                defaults={{
                  content:
                    "Pellentesque ut euismod sem. Etiam iaculis varius metus vel sodales. Fusce venenatis, elit in convallis feugiat, risus augue tempus urna, ut sodales tortor metus posuere ante. Curabitur vitae enim dignissim, vehicula odio a, maximus felis."
                }}
              />
            </Elements.Region>
            <span
              className="formkit-accent"
              style={{ backgroundColor: main_color }}
            />
            <Elements.Heading
              className="formkit-heading-secondary font-display"
              name="form_header"
              tag="h2"
              defaults={{ content: "Subscribe" }}
            />
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields>
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Sign up today" }}
                />
              </Elements.CustomFields>
            </Elements.Form>
            <Elements.Content
              className="formkit-disclaimer font-display"
              name="disclaimer"
              defaults={{
                content: "We respect your privacy. Unsubscribe at any time."
              }}
            />
          </div>
        </div>
        <Elements.Image
          className="formkit-image"
          name="image"
          size={{ h: 400, w: 200 }}
        />
      </div>
      <Elements.BuiltWith background="background" image="background" />
    </Elements.LandingPage>
  )
}

Cypress.style = "landing_page"
Cypress.categories = [
  "eBook",
  "Event",
  "Newsletter",
  "Product",
  "Profile",
  "Webinar"
]
Cypress.thumbnail = ""
Cypress.preview = "https://demo.ck.page/cypress"
Cypress.fields = [
  {
    name: "email_address",
    label: "Your email address"
  }
]

Cypress.beta = true

export default createTemplate(Cypress, { name: "Cypress" })
